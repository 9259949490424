import * as React from "react"
import { Helmet } from 'react-helmet'
import Image from "../../components/image"

// markup
const LevelMinus1Page = () => {
  return (

    <>
      <Helmet title="Sports Direct - Birmingham New Street - Floor -1" defer={false} />
      <Image image="/bns/floor--1.png"/>
    </>

  )
}

export default LevelMinus1Page
